<template>
  <v-app>
    <v-main>
      <div class="wrapper">
        <v-sheet color="Greyscale_002">
          <v-sheet max-width="550" class="mx-auto h-100" outlined>
            <navbar-component />
            <div class="container-fluid py-4">
              <div class="px-xl-4">
                <router-view />
              </div>
            </div>
          </v-sheet>
        </v-sheet>
      </div>
    </v-main>
  </v-app>
</template>
<style></style>
<script>
import NavbarComponent from "../../components/guest/navbar.component";
export default {
  components: {
    NavbarComponent,
  },
  mounted() {},
};
</script>

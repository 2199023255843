<template>
  <div>
    <v-toolbar elevation="0">
      <v-row align="center" justify="center">
        <v-col v-if="$route.path!='/guest/top'" cols="1" class="d-flex justify-center align-center">
          <router-link to="/guest/dashboard">
            <v-btn text color="Main_0003">
              <v-icon large>mdi-chevron-left</v-icon>
            </v-btn>
          </router-link>
        </v-col>
        <v-col v-else cols="1"/>
        <v-col cols="9" class="d-flex justify-center align-center">
          <div v-if="sectionTitle">
            <v-card-title class="Main_0003--text text-h5">
              <b>{{ sectionTitle }}</b>
            </v-card-title>
          </div>
        </v-col>
        <v-col v-if="$route.path!='/guest/top'" cols="1" class="d-flex justify-center align-center">
          <v-btn text @click="dialog = true" color="Main_0003">
            <v-icon large>mdi-menu</v-icon>
          </v-btn>
        </v-col>
        <v-col v-else cols="1"/>
      </v-row>
      <v-dialog v-model="dialog" width="250">
        <v-card outlined>
          <div class="ml-auto">
            <v-list @click.passive="dialog = false" v-if="$route.path!='/guest/top'">
              <router-link to="/guest/dashboard" class="nav-link">
                <i class="mr-3 fas fa-pen"></i>{{ $t('guest.navbar.reserve') }}
              </router-link>
            </v-list>
            <v-list @click.passive="dialog = false" v-if="$route.path!='/guest/top'">
              <router-link to="/guest/setting" class="nav-link">
                <i class="mr-3 fas fa-user"></i>{{ $t('guest.navbar.setting') }}
              </router-link>
            </v-list>
            <v-list @click.passive="dialog = false">
              <router-link to="/guest/charge" class="nav-link">
                <i class="mr-3 fas fa-tag"></i>{{ $t('guest.navbar.charge') }}
              </router-link>
            </v-list>
            <v-list @click="changeLanguage">
              <div class="nav-link">
                <i class="mr-3 fas fa-language"></i>{{ $t('guest.navbar.language') }}
              </div>
            </v-list>
            <v-list @click.passive="dialog = false">
              <router-link to="/guest/terms" class="nav-link">
                <i class="mr-3 fas fa-file-alt"></i>{{ $t('guest.navbar.terms') }}
              </router-link>
            </v-list>
            <v-list href="javascript:void(0)" @click="logout$" v-if="$route.path!='/guest/top'">
              <div class="nav-link">
                <i class="mr-3 fas fa-check-circle"></i>{{ $t('guest.navbar.logoff') }}
              </div>
            </v-list>
          </div>
        </v-card>
      </v-dialog>
    </v-toolbar>
  </div>
</template>

<script>
import { EventBus } from "../../../event-bus";
import config from "@/config/app.config";
import { facilitySettingProfileService } from "@/services/facility/setting/profile.service";

export default {
  data() {
    return {
      airport: {},
      airportReady: false,
      dialog: false
    };
  },
  computed: {
    sectionTitle() {
      const routes = {
        '/guest/top': '仙台空港駐車場予約',
        '/guest/dashboard': 'Top',
        '/guest/setting': '設定',
        '/guest/charge': '利用料金',
        '/guest/reservation': this.getReservationTitle(),
        '/guest/terms': '利用規約',
      };
      const currentPath = this.$route.path;
      for (const route in routes) {
        if (currentPath.startsWith(route)) {
          return routes[route];
        }
      }
      return null;
    },
  },
  async mounted() {
    this.getAirportData(config.airport_id);
  },
  methods: {
    getReservationTitle() {
      if (this.$route.path == '/guest/reservation/create') {
        if (this.form === 'select_date') {
          return '予約の日付を選択';
        } else if (this.form === 'input_form') {
          return '予約内容の登録';
        } else if (this.form === true) {
          return '予約内容確認';
        }
      } else if (this.$route.path == '/guest/reservation/success') {
        return '予約確定';
      }
      return '予約ページ';
    },
    goBack() {
      this.$router.go(-1);
    },
    async getAirportData(id) {
      this.airportReady = false;
      const response = await facilitySettingProfileService.show(id);
      this.airport = response.data;
      this.airportReady = true;
    },
    async logout$() {
      await this.$storage.remove("auth");
      await this.$router.push({ name: "guest-login" });
    },
    changeLanguage() {
      this.$i18n.locale = this.$i18n.locale == 'ja' ? 'en' : 'ja'
      EventBus.$emit('changeLocal');
    },
  },
};
</script>
<style>
#facility-navbar {
  border-width: 2px !important;
  border-color: #2524222b !important;
}
.navbar-brand {
  font-weight: 600;
  font-size: 24px;
  color: #252422 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.navbar a {
  color: inherit;
}
.nav-link {
  font-weight: 500;
  color: #555555 !important;
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.nav-item:last-child .nav-link {
  padding-right: 0 !important;
}
@media only screen and (max-width: 425px) {
  .navbar-brand {
    font-size: 16px;
  }
}
</style>
